import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";

import BackgroundImage from "../components/Home/BackgroundImage"
import About from "../components/Home/About"
import WhyTroLetInn from "../components/Home/WhyTroLetInn"
import Services from "../components/Home/Services"
import FamousPlaces from "../components/Home/FamousPlaces"
import Gallery from "../components/Home/Gallery"
import Testimonial from "../components/Home/Testimonial"
import ContactUs from "../components/Home/ContactUs"
import SEO from "../components/seo"

import "popper.js"

import "../css/swiper.min.scss"
import "../css/bootstrap.min.scss"
import "../css/custom.scss"
import "aos/dist/aos.css"

const IndexPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { siteData },
    },
  } = data

  return (
    <Layout>
      <SEO title="Home" description="Home description" />
      <BackgroundImage backgroundContent={siteData.background} />
      <About aboutContent={siteData.about} />
      <WhyTroLetInn troletContent={siteData.whyTrolet} />
      <Services servicesContent={siteData.services} />
      <FamousPlaces famousContent={siteData.famousPlaces} />
      <Gallery galleryContent={siteData.gallery} />
      <ContactUs contContent={siteData.contactus} />
    </Layout>
  )
}
// <Testimonial testimonialContent={siteData.testimonial} />
export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        siteData {
          background {
            topRightImg
            topLeftImg
            ujjainImg
            rudrakshaImg
            brand
            map
            pathTop
            heroRight
            heroLeft
            title
          }
          about {
            title
            text
            button
          }
          whyTrolet {
            img1 {
              image {
                src
              }
            }
            img2 {
              image {
                src
              }
            }
            img3 {
              image {
                src
              }
            }
            title
            text
          }
          services {
            url
          }
          famousPlaces {
            id
            imgUrl
            title
          }
          gallery {
            img1
            img2
            img3
            img4
            img5
            img6
            title
            description
          }
          testimonial {
            lineUrl
            mapImage
          }
          contactus {
            phoneCont {
              url
              title
              text
            }
            emailCont {
              url
              title
              text
            }
            workingHours {
              title
              text
            }
            addressCont {
              url
              title
              text
            }
          }
        }
      }
    }
  }
`

export default IndexPage
