import React, { useEffect } from "react"
import AOS from "aos"
import PropTypes from "prop-types"

const Gallery = ({ galleryContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])

  return (
    <div id="gallery" className="gallery">
      <div className="container">
        <div className="gallery-grid">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="gallery-item item-Xlarge" data-aos="fade-up">
                    <div className="image image-border">
                      <img
                        src={galleryContent.img1}
                        className="img-fluid"
                        alt="img1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="gallery-item"
                    data-aos="fade-up"
                    data-aos-duration="300"
                  >
                    <div className="image">
                      <img
                        src={galleryContent.img2}
                        className="img-fluid"
                        alt="img2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div
                    className="gallery-item"
                    data-aos="fade-up"
                    data-aos-duration="700"
                  >
                    <div className="image image-border">
                      <img
                        src={galleryContent.img3}
                        className="img-fluid"
                        alt="img3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="gallery-content">
                <div
                  className="gallery-item"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <h2 className="title">{galleryContent.title}</h2>
                  <p>{galleryContent.description}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className="gallery-item item-Xlarge"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    <div className="image">
                      <img
                        src={galleryContent.img4}
                        className="img-fluid"
                        alt="img4"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="gallery-item"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <div className="image">
                      <img
                        src={galleryContent.img5}
                        className="img-fluid"
                        alt="img5"
                      />
                    </div>
                  </div>
                  <div
                    className="gallery-item"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="image">
                      <img
                        src={galleryContent.img6}
                        className="img-fluid"
                        alt="img6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery.propTypes = {
  galleryContent: PropTypes.object
}

export default Gallery
