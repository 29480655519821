import React, { useEffect, useState } from "react"
import { store } from "react-notifications-component"
import AOS from "aos"
import DatePicker from "react-datepicker"
import moment from "moment"
import Tilt from "react-tilt"
import PropTypes from "prop-types"


import "react-datepicker/dist/react-datepicker.css"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactUs = ({ contContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])
  const [formData, setFormData] = useState(null)
  const [getDate, setDate] = useState(null)
  const handleChange = (e, type) => {
    if (type) {
      setDate(e)
      const dateFormat = moment(e).format("YYYY/MM/DD")
      setFormData({ ...formData, [type]: dateFormat })
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setFormData(null)
        setDate(null)
        store.addNotification({
          title: "Contact Us",
          message: "Data added successfully",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        })
      })
      .catch(error => console.log("error>>>", error))
  }
  return (
    <div className="container contactus">
      <div id="contactUs">
        <div className="contact" data-aos="fade-up" data-aos-duration="1500">
          <div className="contact-row">
            <Tilt
              className="Tilt"
              options={{
                reverse: false, // reverse the tilt direction
                max: 50, // max tilt rotation (degrees)
                perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
                scale: 1, // 2 = 200%, 1.5 = 150%, etc..
                speed: 500, // Speed of the enter/exit transition
                transition: true, // Set a transition on enter/exit.
                axis: null, // What axis should be disabled. Can be X or Y.
                reset: true, // If the tilt effect has to be reset on exit.
                easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
              }}
            // style={{ height: 250, width: 250 }}
            >
              <div className="contact-box">
                <div className="contact-ic ic-black">
                  <div className="Tilt-inner">
                    <img src={contContent.phoneCont.url} alt="phone" className="img-fluid" />
                  </div>
                </div>
                <h4>{contContent.phoneCont.title}</h4>
                <p>{contContent.phoneCont.text}</p>
              </div>
            </Tilt>
            <Tilt
              className="Tilt"
              options={{
                reverse: false, // reverse the tilt direction
                max: 50, // max tilt rotation (degrees)
                perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
                scale: 1, // 2 = 200%, 1.5 = 150%, etc..
                speed: 500, // Speed of the enter/exit transition
                transition: true, // Set a transition on enter/exit.
                axis: null, // What axis should be disabled. Can be X or Y.
                reset: true, // If the tilt effect has to be reset on exit.
                easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
              }}
            // style={{ height: 250, width: 250 }}
            >
              <div className="contact-box">
                <div className="contact-ic">
                  <img src={contContent.emailCont.url} alt="email" className="img-fluid" />
                </div>
                <h4>{contContent.emailCont.title}</h4>
                <p>{contContent.emailCont.text}</p>
              </div>
            </Tilt>
            <Tilt
              className="Tilt"
              options={{
                reverse: false, // reverse the tilt direction
                max: 50, // max tilt rotation (degrees)
                perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
                scale: 1, // 2 = 200%, 1.5 = 150%, etc..
                speed: 500, // Speed of the enter/exit transition
                transition: true, // Set a transition on enter/exit.
                axis: null, // What axis should be disabled. Can be X or Y.
                reset: true, // If the tilt effect has to be reset on exit.
                easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
              }}
            >
              <div className="contact-box">
                <div className="contact-ic ic-orange-darker">
                  <img src={contContent.addressCont.url} alt="address" className="img-fluid" />
                </div>
                <h4>{contContent.addressCont.title}</h4>
                <p>{contContent.addressCont.text}</p>
              </div>
            </Tilt>
          </div>
        </div>
      </div>
      <div className="get-in-touch" data-aos="fade-up" data-aos-duration="1500">
        <div className="c-form">
          <h2 className="title">Get In Touch</h2>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name" className="label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    required
                    value={formData ? formData.name : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    required
                    name="email"
                    onChange={handleChange}
                    value={formData ? formData.email : ""}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mobile" className="label">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="mobile"
                    required
                    name="mobile"
                    onChange={handleChange}
                    value={formData ? formData.mobile : ""}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Visit Date
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={getDate}
                    onChange={date => handleChange(date, "date")}
                    dateFormat="dd/MM/yyyy"
                    required
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-default btn-theme">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactUs.propTypes = {
  contContent: PropTypes.object
}

export default ContactUs
