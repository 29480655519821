import React, { useEffect } from "react"
import AOS from "aos"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Line from "../Line"

import "../../css/animation-style.scss"
import { BOOK_NOW_URL } from "../../constant/Links"

const BackgroundImage = ({ backgroundContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])

  return (
    <div id="home" className="hero">
      <div className="booknow-contact">
          <a className="btn btn-outline-warning" href={BOOK_NOW_URL} 
          // target="_blank"
          > Book Now</a>
          <Link className="btn btn-outline-danger" to="/#contactUs">  Contact Us</Link>
      </div>
      <div className="TopPath">
        <Line
          id="TopPath"
          svg={backgroundContent.pathTop}
          width="100%"
          height="100%"
        />
      </div>
      <div className="BottomLeftPath">
        <span className="BottomLeftImg">
          <img src={backgroundContent.map} alt="logo" />
        </span>
        <Line
          id="BottomLeftPath"
          svg={backgroundContent.heroLeft}
          width="100%"
          height="100%"
        />
      </div>
      <div className="BottomRightPath">
        <span className="BottomRightImg">
          <img
            src={backgroundContent.ujjainImg}
            className="ujjain-img"
            alt="ujjain-img"
          />
          <img src={backgroundContent.rudrakshaImg} alt="rudraksha-img" />
        </span>
        <Line
          id="BottomRightPath"
          svg={backgroundContent.heroRight}
          width="100%"
          height="100%"
        />
      </div>
      <div className="hero-center">
        <div className="hero-logo" data-aos="fade-up" data-aos-delay="1500">
          <img src={backgroundContent.brand} alt="logo" />
        </div>

        <h3 data-aos="fade-up" data-aos-delay="1800">
          {backgroundContent.title}
        </h3>

        {/* <div
          className="icon-scroll"
          data-aos="zoom-in-up"
          data-aos-delay="700"
        ></div> */}
      </div>
      <div className="left-img" data-aos="fade-right" data-aos-delay="500">
        <img src={backgroundContent.topLeftImg} alt="left" />
      </div>
      <div className="right-img" data-aos="fade-left" data-aos-delay="500">
        <img src={backgroundContent.topRightImg} alt="right" />
      </div>

      <div className="topAnimation">
        <div id="puppy" className="🐕">
          <div className="torso">
            <div className="fur">
              <div className="spot"></div>
            </div>
            <div className="neck">
              <div className="fur"></div>
              <div className="head">
                <div className="fur">
                  <div className="snout"></div>
                </div>
                <div className="ears">
                  <div className="ear">
                    <div className="fur"></div>
                  </div>
                  <div className="ear">
                    <div className="fur"></div>
                  </div>
                </div>
                <div className="eye"></div>
              </div>
              <div className="collar"></div>
            </div>
            <div className="legs">
              <div className="leg">
                <div className="fur"></div>
                <div className="leg-inner">
                  <div className="fur"></div>
                </div>
              </div>
              <div className="leg">
                <div className="fur"></div>
                <div className="leg-inner">
                  <div className="fur"></div>
                </div>
              </div>
              <div className="leg">
                <div className="fur"></div>
                <div className="leg-inner">
                  <div className="fur"></div>
                </div>
              </div>
              <div className="leg">
                <div className="fur"></div>
                <div className="leg-inner">
                  <div className="fur"></div>
                </div>
              </div>
            </div>
            <div className="tail">
              <div className="tail">
                <div className="tail">
                  <div className="tail -end">
                    <div className="tail">
                      <div className="tail">
                        <div className="tail">
                          <div className="tail"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h6 style={{ textAlign: "center" }}>Scroll Down</h6> */}
      </div>
    </div>
  )
}

BackgroundImage.propTypes = {
  backgroundContent: PropTypes.object
}

export default BackgroundImage
