import React, { useEffect } from "react"
import AOS from "aos"
import PropTypes from "prop-types"

import Line from "../Line"

//import { firstServices, secondService } from "../../constant/services"
import ServicesData from "../../constant/services"

const Services = ({ servicesContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])
  return (
    <div id="features" className="features">
      <div className="path-image">
        <Line
          id="feature-path-img"
          svg={servicesContent.url}
          width="100%"
          height="100%"
        />
      </div>
      <div className="container">
        <div className="features-row">
          {ServicesData.map((items, index) => {
            return (
              <div
                key={index}
                className="feature-box"
                data-aos="fade-up"
                data-aos-duration="300"
              >
                <span className="feature-img">{items.icon}</span>
                <h6>{items.title}</h6>
              </div>
            )
          })}
        </div>
        {/* <div className="features-row" data-aos="fade-up">
          {secondService.map((items, index) => {
            return (
              <div key={index} className="feature-box">
                <span className="feature-img">{items.icon}</span>
                <h6>{items.title}</h6>
              </div>
            )
          })}
        </div> */}
      </div>
    </div>
  )
}

Services.propTypes = {
  servicesContent: PropTypes.object,
}

export default Services
