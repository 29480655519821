import React, { useEffect } from "react"
import AOS from "aos"
import PropTypes from "prop-types"

const WhyTroLetInn = ({ troletContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])

  return (
    <div id="whyTrolet" className="why-trolet">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-xl-7">
            <div className="img-card">
              <div className="">
                <div
                  className="para-img para-img1 image-border"
                  data-aos="fade-left"
                >
                  <img
                    src={troletContent.img3.image.src}
                    alt="Trolet Inn"
                    className="img-fluid"
                  />
                </div>
                <div
                  className="para-img para-img2 image-border"
                  data-aos="fade-right"
                >
                  <img
                    src={troletContent.img1.image.src}
                    alt="Trolet Inn"
                    className="img-fluid "
                  />
                </div>
                <div
                  className="para-img para-img3 image-border"
                  data-aos="fade-left"
                  data-aos-delay="500"
                >
                  <img
                    src={troletContent.img2.image.src}
                    alt="Trolet Inn"
                    className="img-fluid "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-5" data-aos="fade-left">
            <h2 className="title">{troletContent.title}</h2>
            <p>{troletContent.text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

WhyTroLetInn.propTypes = {
  troletContent: PropTypes.object
}

export default WhyTroLetInn
