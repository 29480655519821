import React, { useEffect } from "react"
import Swiper from "swiper/js/swiper"
import AOS from "aos"
import PropTypes from "prop-types"

const timer = () => {
  new Swiper(".famous-swiper", {
    speed: 400,
    loop: true,
    slidesPerView: 5,
    spaceBetween: 0,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  })
}

const FamousPlaces = ({ famousContent }) => {
  useEffect(() => {
    timer()
    AOS.init({
      duration: 1500,
    })
  }, [])

  return (
    <div id="famous" className="famous">
      <div
        className="container-fluid p-0"
        data-aos="fade-in"
        data-aos-duration="700"
      >
        <div className="famous-title">
          <h2 className="title">Famous Around Ujjain</h2>
        </div>
        <div className="swiper-container famous-swiper">
          <div className="swiper-wrapper">
            {famousContent &&
              famousContent.map(({ id, imgUrl, title }) => {
                return (
                  <div className="swiper-slide" key={id}>
                    <div className="famous-wrap">
                      <img src={imgUrl} alt={id} className="img-fluid" />
                      <div className="overlay-caption">
                        {/* <Link
                          to={`/blog/${title.toLowerCase()}`}
                          className="btn btn-default btn-outline-light"> */}
                        <h3>{title}</h3>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </div>
  )
}

FamousPlaces.propTypes = {
  famousContent: PropTypes.array,
}

export default FamousPlaces
