import React, { useEffect } from "react"
import AOS from "aos"
import PropTypes from "prop-types"


import "../../js/custom"

const About = ({ aboutContent }) => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    })
  }, [])
  return (
    <div id="about">
      <div className="about-card">
        <div className="parallax-bg">
          <div className="shape shape-layer5"></div>
          <div className="shape shape-layer4"></div>
        </div>
        <div className="container" data-aos="fade-up" data-aos-duration="1500">
          <h2>{aboutContent.title}</h2>
          <p>
            Welcome! So, you are planning to visit Ujjain – the second oldest city in the country with a recorded History. Of course, the famous Mahakaleshwar Temple is on your list of things to do. But did you also know that no member of a Royal Family is allowed to spend the night in Ujjain – because two “Kings” cannot live together in one city and Mahakal is the “King” of Ujjain? Or that Raja Vikramaditya (of the Vikram and Betal fame) built his Palace outside of the city for the same reason? Or that there was an underground tunnel connecting this Palace to Mahakaleshwar Temple so that water can be brought from the Royal household to the Temple for “Jalabhishek”.<br />

            We at TROLET welcome you to explore such hidden stories – beyond the popular legends. Which is why we call ourselves TROLET – to celebrate *T*he *RO*ad *LE*ss *T*ravelled. <br />

            We love our city – give us a chance and we will talk about it forever. And we want a lot more people to experience the best of this beautiful place. With this agenda in mind, we have set out on this small but exciting trip called TROLET.<br />

            We will take care of your accommodation, arrange for you to visit the lesser known wonders in and around the city, treat you to best of local cuisine, teach you a slang or two in our colorful dialect and make sure you have vacation with great memories beyond just selfies and Facebook Posts.<br />
            <br />
            Come. See. Fall in Love.
          </p>
        </div>
      </div>
    </div>
  )
}

About.propTypes = {
  aboutContent: PropTypes.object
}

export default About
