import React from "react"
import ReactVivus from "react-vivus"
import PropTypes from "prop-types"

const Line = ({ id, svg, width, height, position, left }) => {
  return (
    <ReactVivus
      id={id}
      option={{
        file: svg,
        animTimingFunction: "EASE",
        type: "oneByOne",
      }}
      style={{
        height: height,
        width: width,
        position: position ? position : "static",
        left: left ? left : "0",
      }}
    />
  )
}
Line.propTypes = {
  id: PropTypes.string.isRequired,
  svg: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

export default Line
