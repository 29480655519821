import React from "react"
import {
  FaCar,
  FaPaw,
  FaChild,
  FaWifi,
  FaHamburger,
  FaRegSnowflake,
  FaUtensils,
  FaSwimmer,
  FaConciergeBell,
  FaWineGlassAlt,
} from "react-icons/fa"

export default [
  {
    icon: <FaCar />,
    title: "Free Parking",
  },
  {
    icon: <FaPaw />,
    title: "Pets Allowed",
  },
  {
    icon: <FaChild />,
    title: "Babysitting",
  },
  {
    icon: <FaWifi />,
    title: "Free Internet",
  },
  {
    icon: <FaHamburger />,
    title: "Free breakfast",
  },
  {
    icon: <FaRegSnowflake />,
    title: "Air conditioning",
  },
  {
    icon: <FaUtensils />,
    title: "BBQ facilities",
  },
  {
    icon: <FaSwimmer />,
    title: "Children Activities",
  },
  {
    icon: <FaConciergeBell />,
    title: "Room service",
  },
  {
    icon: <FaWineGlassAlt />,
    title: "Minibar",
  },
]

export const secondService = [
  {
    icon: <FaRegSnowflake />,
    title: "Air conditioning",
  },
  {
    icon: <FaUtensils />,
    title: "BBQ facilities",
  },
  {
    icon: <FaSwimmer />,
    title: "Children Activities",
  },
  {
    icon: <FaConciergeBell />,
    title: "Room service",
  },
  {
    icon: <FaWineGlassAlt />,
    title: "Minibar",
  },
]
